<template>
	<div>
		<vx-card actionable class="cardx" title="Application Batch Update">
			<vs-table :data="trackingData">
				<template slot="thead">
					<vs-th>Can-ID</vs-th>
					<vs-th>Can.Name</vs-th>
					<vs-th>Course</vs-th>
					<vs-th>Batch</vs-th>
					<!--<vs-th>Email</vs-th> -->
					<!-- <vs-th>Levels</vs-th> -->
					<vs-th>ExEd Spoc</vs-th>
					<!-- <vs-th>Application Status</vs-th> -->
					<vs-th>Action</vs-th>
				</template>
				<template slot-scope="{ data }">
					<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
						<vs-td>
							{{ tr.identity }}
						</vs-td>
						<vs-td>
							{{ tr.person_name }}
						</vs-td>
						<vs-td>
							{{ tr.course }}
						</vs-td>
						<vs-td>
							<p v-if="tr.batch !== null">{{ tr.batch }}</p>
							<p v-else>No Batch Updated</p>
						</vs-td>
						<!--<vs-td>
              {{ tr.email }}
            </vs-td> -->
						<!-- <vs-td>
              {{ tr.application_sub_level }}
            </vs-td> -->
						<vs-td>
							{{ getSpocName(tr.assigned_iiml_id) }}
						</vs-td>
						<!-- <vs-td>
              {{ tr.application_status }}
            </vs-td> -->
						<vs-td>
							<vs-row>
								<div @click="openPopupEmit(tr)">
									<vs-icon
										icon="info_outline"
										size="small"
										color="dark"
									></vs-icon>
								</div>
								<div @click="edit(tr)" style="margin-left: 5%;">
									<vs-icon
										icon="edit"
										size="small"
										color="dark"
									></vs-icon>
								</div>
								<!-- <vs-button size="small" @click="edit(tr)" color="warning" style="margin-left: 5%;">Edit</vs-button> -->
								
							</vs-row>
							
						</vs-td>
					</vs-tr>
				</template>
			</vs-table>
			<vs-row style="margin-top: 3%">
				<vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
				<vs-chip color="primary" style="margin-right: 2%">
					<b>{{ countsleads }}</b>
				</vs-chip>
				<vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
				</vs-col>
			</vs-row>
		</vx-card>
		<vs-popup
			:title="
				this.selectedApplication.person_name +
				' - ' +
				selectedApplication.course +
				' - ' +
				batchcode(selectedApplication)
			"
			:active.sync="masterTrackerPopup"
			v-if="masterTrackerPopup"
		>
			<div>
				<div class="">
					<vs-row class="gap">
						<vs-col vs-w="2"> Batch : </vs-col>
						<vs-col vs-w="2">
							<vs-input v-model="batchInput" disabled></vs-input>
						</vs-col>
						<vs-col vs-w="3"></vs-col>

						<vs-col vs-w="3">
							<v-select
								v-model="batchNum"
								placeholder="code"
								:options="batchcodenumber"
							></v-select>
						</vs-col>
						<vs-col vs-w="2"></vs-col>
					</vs-row>
					<vs-row class="gap">
						<vs-col vs-w="12">
							<v-select
								v-model="selectedlevel"
								placeholder="select Level"
								:options="Level"
							></v-select>
						</vs-col>
					</vs-row>
					<vs-row class="gap">
						<vs-col vs-w="12">
							<v-select
								placeholder="select Sub Level"
								v-model="selectedSublevel"
								:options="subLevel[0]"
							></v-select>
						</vs-col>
					</vs-row>
					<br /><br />
					<vs-row class="gap">
						<vs-col style="display: flex; justify-content: center">
							<vs-button color="primary" type="filled" @click="UpdateLevel()"
								>Update</vs-button
							>
						</vs-col>
					</vs-row>
				</div>
			</div>
		</vs-popup>
		<vs-popup
			:title="
				this.selectedApplicationData.person_name +
				' - ' +
				selectedApplicationData.course +
				' - ' +
				batchcode(selectedApplicationData)
			"
			:active.sync="masterDataPopup"
			v-if="masterDataPopup"
		>
			<div>
				<div class="">
					<vs-row class="gap">
						<vs-col vs-w="6"> Person Name : {{ this.selectedApplicationData.person_name }} </vs-col>
						<vs-col vs-w="6"> Course : {{ this.selectedApplicationData.course }} </vs-col>
					</vs-row>
					<vs-row class="gap">
						<vs-col vs-w="6"> Can-ID : {{ this.selectedApplicationData.identity }} </vs-col>
						<vs-col vs-w="6"> Batch : {{ batchcode(this.selectedApplicationData) }} </vs-col>
					</vs-row>
					<vs-row class="gap">
						<vs-col vs-w="6"> Spoc Name : {{ getSpocName(this.selectedApplicationData.assigned_iiml_id) }} </vs-col>
						<vs-col vs-w="6"> City : {{ this.selectedApplicationData.city }} </vs-col>
					</vs-row>
					<vs-row class="gap">
						<vs-col vs-w="8"> Application created Date : {{ this.createddate }} </vs-col>
						<!-- <vs-col vs-w="6"> Course : {{ this.selectedApplicationData.course }} </vs-col> -->
					</vs-row>
				</div>
			</div>
		</vs-popup>
	</div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
// import eventbus from "../components/eventbus";
import moment from "moment";
import LoadingButton from "../components/utilities/LoadingButton.vue";
export default {
	components: {
		"v-select": vSelect,
		LoadingButton,
		moment
	},
	mounted() {
		this.getSpoctree();
		this.getApplicationTrackerData();
	},
	data() {
		return {
			selectedBatch: "",
			batchNum: "",
			batchcodenumber: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
			],
			batchInput: "",
			rawBde: [],
			assign_now_loading: false,
			selectedApplication: null,
			selected_radio_data: "",
			trakerRadio: "",
			masterTrackerPopup: false,
			masterDataPopup: false,
			selectedlevel: "",
			Level: ["Enrollments", "Application Received", "Lost Leads"],
			listtracking: [],
			trackingData: [],
			selectedSublevel: "",
			subLevel: [],
			getpopupdata: [],
			selected_data: [],
			selectedlevelid: "",
			ApplicationSubLevelId: "",
			applicationID: "",
			currentTablePage: 1,
			countsleads: 0,
			tablelinks: 0,
			createddate: ""
		};
	},

	watch: {
		batchNum(val) {
			this.selectedBatch = this.batchInput + val;
		},
		selectedlevel: function (value) {
			console.log(value);
			if (value === "Enrollments") {
				this.ApplicationLevelId = 1;
				this.subLevel = [];
				let SubEnrlevelArr = [
					"Enrolled - Application Accepted - Full Payment Received",
					"Enrolled - Application Accepted - Part Payment Received",
					"Enrolled - Application Accepted - Part Payment Received - Loan Candidate",
					"Old Enrollments",
					"Enrolled Special Candidates",
				];
				this.subLevel.push(SubEnrlevelArr);
				console.log(this.subLevel);
			} else if (value === "Application Received") {
				this.ApplicationLevelId = 2;
				this.subLevel = [];
				let SubEnrlevelArr = [
					"Entrance Scheduled",
					"Entrance Passed - Application Accepted",
					"Entrance Passed - Application Approval Awaited",
					"Entrance Failed - Retest Scheduled",
					"Entrance Failed - Retest Proposed",
					"Documents Requested - Not Received",
					"Enrolled Special Candidates",
				];
				this.subLevel.push(SubEnrlevelArr);
				console.log(this.subLevel);
			} else if (value === "Lost Leads") {
				this.ApplicationLevelId = 3;
				this.subLevel = [];
				let SubEnrlevelArr = [
					"Invalid Leads/Contacts",
					"Entrance Passed - Application Rejected",
					"Entrance Passed - Dropped Out",
					"Entrance Passed - Application Accepted - Loan Rejected",
					"Entrance No show - Dropped Out",
					"Entrance Failed - Dropped Out & No Retest",
					"Candidate Refunded",
					"Old application Received",
				];
				this.subLevel.push(SubEnrlevelArr);
				console.log(this.subLevel);
			}
		},
		selectedSublevel(value) {
			console.log(value);
			if (value === "Enrolled - Application Accepted - Full Payment Received") {
				this.ApplicationSubLevelId = 1;
			} else if (
				value === "Enrolled - Application Accepted - Part Payment Received"
			) {
				this.ApplicationSubLevelId = 2;
			} else if (
				value ===
				"Enrolled - Application Accepted - Part Payment Received - Loan Candidate"
			) {
				this.ApplicationSubLevelId = 3;
			} else if (value === "Entrance Scheduled") {
				this.ApplicationSubLevelId = 4;
			} else if (value === "Entrance Passed - Application Accepted") {
				this.ApplicationSubLevelId = 5;
			} else if (value === "Entrance Passed - Application Approval Awaited") {
				this.ApplicationSubLevelId = 6;
			} else if (value === "Entrance Failed - Retest Scheduled") {
				this.ApplicationSubLevelId = 7;
			} else if (value === "Entrance Failed - Retest Proposed") {
				this.ApplicationSubLevelId = 8;
			} else if (value === "Documents Requested - Not Received") {
				this.ApplicationSubLevelId = 9;
			} else if (value === "Invalid Leads/Contacts") {
				this.ApplicationSubLevelId = 10;
			} else if (value === "Entrance Passed - Application Rejected") {
				this.ApplicationSubLevelId = 11;
			} else if (value === "Entrance Passed - Dropped Out") {
				this.ApplicationSubLevelId = 12;
			} else if (
				value === "Entrance Passed - Application Accepted - Loan Rejected"
			) {
				this.ApplicationSubLevelId = 13;
			} else if (value === "Entrance No show - Dropped Out") {
				this.ApplicationSubLevelId = 14;
			} else if (value === "Entrance Failed - Dropped Out & No Retest") {
				this.ApplicationSubLevelId = 15;
			} else if (value === "Candidate Refunded") {
				this.ApplicationSubLevelId = 16;
			} else if (value === "Old Enrollments") {
				this.ApplicationSubLevelId = 17;
			} else if (value === "Enrolled Special Candidates") {
				this.ApplicationSubLevelId = 20;
			} else if (value === "Old Lost Leads") {
				this.ApplicationSubLevelId = 18;
			} else if (value === "Old application Received") {
				this.ApplicationSubLevelId = 19;
			}
		},
		currentTablePage: function () {
			this.getApplicationTrackerData();
		},
	},
	methods: {
		batchcode(item) {
			if (item.course === "IIMK-CX") {
				this.batchInput = "IIMK-CX-";
			} else if (item.course === "IIML-FA") {
				this.batchInput = "IIML-FA-";
			} else if (item.course === "IIML-BA") {
				this.batchInput = "IIML-BA-";
			} else if (item.course === "IIML-PM") {
				this.batchInput = "IIML-PM-";
			} else if (item.course === "IIML-HR") {
				this.batchInput = "IIML-HR-";
			} else if (item.course === "IITR-BF") {
				this.batchInput = "IITR-BF-";
			} else if (item.course === "IITR-DB") {
				this.batchInput = "IITR-DB-";
			} else if (item.course === "IIML-SF") {
				this.batchInput = "IIML-SF-";
			} else if (item.course === "IIML-FT") {
				this.batchInput = "IIML-FT-";
			} else if (item.course === "IITM-AA") {
				this.batchInput = "IITM-AA-";
			} else if (item.course === "IITM-FS") {
				this.batchInput = "IITM-FS-";
			} else if (item.course === "IITR-CC") {
				this.batchInput = "IITR-CC-";
			} else if (item.course === "IIMK-FT") {
				this.batchInput = "IIMK-FT-";
			} else if (item.course === "IIML-AB") {
				this.batchInput = "IIML-AB-";
			} else if (item.course === "IIML-SH") {
				this.batchInput = "IIML-SH-";
			} else if (item.course === "IITJ-DE") {
				this.batchInput = "IITJ-DE-";
			} else if (item.course === "XLRI-HR") {
				this.batchInput = "XLRI-HR-";
			} else if (item.course === "XLRI-SH") {
				this.batchInput = "XLRI-SH-";
			} else if (item.course === "IIMI-BA") {
				this.batchInput = "IIMI-BA-";
			}	else if (item.course === "IIMI-AA") {
				this.batchInput = "IIMI-AA-";
			}	else if (item.course === "XLRI-DM") {
				this.batchInput = "XLRI-DM-";
			}
			if (item.batch === null) {
				return "No Batch Updated";
			}
			return item.batch;
		},
		getSpocName(spoc_id) {
			let name = "";
			// console.log(this.rawBde);
			this.rawBde.forEach((spoc) => {
				if (spoc_id === spoc.id) {
					name = spoc.first_name;
					console.log(name);
				}
			});
			return name;
		},
		getSpoctree() {
			let url = `${constants.ADMIN_SERVER_API}getExed`;
			axios
				.get(url, {
					headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
				})
				.then(async (response) => {
					this.rawBde = response.data.spocs;
				})
				.catch((error) => {
					this.handleError(error);
				});
		},
		getApplicationTrackerData() {
			//   let url = "";
			// let page = this.currentTablePage
			let url = `${constants.SERVER_API}applicationTracker?page=${this.currentTablePage}`;
			axios
				.get(url, {
					headers: {
						// params: page,
						Authorization: `Bearer ${localStorage.userAccessToken}`,
					},
				})
				.then((response) => {
					console.log("Application trackingData", response);
					this.countsleads = response.data.total;
					this.currentTablePage = response.data.current_page;
					this.tablelinks = response.data.last_page;
					this.trackingData = response.data.data;
				})
				.catch((error) => {
					this.handleError(error);
				});
		},
		edit(tr) {
			console.log(tr);
			//   this.selectedlevel = tr.application_sub_level;
			//   this.selectedlevelid = tr.application_sub_level_id;
			this.ApplicationSubLevelId = "";
			this.ApplicationLevelId = "";
			this.applicationID = tr.id;
			this.selectedApplication = tr;
			console.log("den", this.applicationID, this.selectedApplication);
			this.masterTrackerPopup = true;
		},
		openPopupEmit(tr) {
			console.log(tr);
			this.openBigPopup(tr.mwb_id);
		},
		UpdateLevel() {
			console.log("updated");
			// this.assign_now_loading = true;
			let obj = {
				batch: this.selectedBatch,
				application_level_id: this.ApplicationLevelId,
				application_sub_level_id: this.ApplicationSubLevelId,
			};
			if (
				obj.batch !== "" &&
				obj.application_level_id !== "" &&
				obj.application_sub_level_id !== ""
			) {
				let url = `${constants.SERVER_API}applicationTracker/${this.applicationID}`;
				axios
					.put(url, obj, {
						headers: {
							Authorization: `Bearer ${localStorage.userAccessToken}`,
						},
					})
					.then((response) => {
						console.log("My Result", response);

						// this.assign_now_loading = false;

						this.$vs.notify({
							title: "Updated",
							text: "Successfully",
							color: "success",
						});
						this.masterTrackerPopup = false;
						this.getApplicationTrackerData();
					})
					.catch((error) => {
						this.handleError(error);
					});
			} else {
				this.$vs.notify({
					title: "Do Not Leave Empty Fields",
					text: "Please Fill All The Feilds",
					color: "warning",
				});
			}
		},
	},
};
</script>

<style>
.vhd-input[data-v-25b15922] {
	min-width: 250px;
	padding: 5px;
	border: 1px solid #eee;
	color: #505050;
	font-size: 16px;
	line-height: 9px;
	border-radius: 8px;
	border-color: lightgray;
	outline: none;
}
.vs--searchable .vs__dropdown-toggle {
	height: 38px;
	font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
	font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
	font-size: 13px;
}
</style>
