var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"cardx",attrs:{"actionable":"","title":"Application Batch Update"}},[_c('vs-table',{attrs:{"data":_vm.trackingData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(tr.identity)+"\n\t\t\t\t\t\t")]),_c('vs-td',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(tr.person_name)+"\n\t\t\t\t\t\t")]),_c('vs-td',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(tr.course)+"\n\t\t\t\t\t\t")]),_c('vs-td',[(tr.batch !== null)?_c('p',[_vm._v(_vm._s(tr.batch))]):_c('p',[_vm._v("No Batch Updated")])]),_c('vs-td',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.getSpocName(tr.assigned_iiml_id))+"\n\t\t\t\t\t\t")]),_c('vs-td',[_c('vs-row',[_c('div',{on:{"click":function($event){return _vm.openPopupEmit(tr)}}},[_c('vs-icon',{attrs:{"icon":"info_outline","size":"small","color":"dark"}})],1),_c('div',{staticStyle:{"margin-left":"5%"},on:{"click":function($event){return _vm.edit(tr)}}},[_c('vs-icon',{attrs:{"icon":"edit","size":"small","color":"dark"}})],1)])],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Can-ID")]),_c('vs-th',[_vm._v("Can.Name")]),_c('vs-th',[_vm._v("Course")]),_c('vs-th',[_vm._v("Batch")]),_c('vs-th',[_vm._v("ExEd Spoc")]),_c('vs-th',[_vm._v("Action")])],1)],2),_c('vs-row',{staticStyle:{"margin-top":"3%"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-end","vs-w":"12"}},[_c('vs-chip',{staticStyle:{"margin-right":"2%"},attrs:{"color":"primary"}},[_c('b',[_vm._v(_vm._s(_vm.countsleads))])]),_c('vs-pagination',{attrs:{"total":_vm.tablelinks},model:{value:(_vm.currentTablePage),callback:function ($$v) {_vm.currentTablePage=$$v},expression:"currentTablePage"}})],1)],1)],1),(_vm.masterTrackerPopup)?_c('vs-popup',{attrs:{"title":this.selectedApplication.person_name +
				' - ' +
				_vm.selectedApplication.course +
				' - ' +
				_vm.batchcode(_vm.selectedApplication),"active":_vm.masterTrackerPopup},on:{"update:active":function($event){_vm.masterTrackerPopup=$event}}},[_c('div',[_c('div',{},[_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"2"}},[_vm._v(" Batch : ")]),_c('vs-col',{attrs:{"vs-w":"2"}},[_c('vs-input',{attrs:{"disabled":""},model:{value:(_vm.batchInput),callback:function ($$v) {_vm.batchInput=$$v},expression:"batchInput"}})],1),_c('vs-col',{attrs:{"vs-w":"3"}}),_c('vs-col',{attrs:{"vs-w":"3"}},[_c('v-select',{attrs:{"placeholder":"code","options":_vm.batchcodenumber},model:{value:(_vm.batchNum),callback:function ($$v) {_vm.batchNum=$$v},expression:"batchNum"}})],1),_c('vs-col',{attrs:{"vs-w":"2"}})],1),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('v-select',{attrs:{"placeholder":"select Level","options":_vm.Level},model:{value:(_vm.selectedlevel),callback:function ($$v) {_vm.selectedlevel=$$v},expression:"selectedlevel"}})],1)],1),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('v-select',{attrs:{"placeholder":"select Sub Level","options":_vm.subLevel[0]},model:{value:(_vm.selectedSublevel),callback:function ($$v) {_vm.selectedSublevel=$$v},expression:"selectedSublevel"}})],1)],1),_c('br'),_c('br'),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('vs-button',{attrs:{"color":"primary","type":"filled"},on:{"click":function($event){return _vm.UpdateLevel()}}},[_vm._v("Update")])],1)],1)],1)])]):_vm._e(),(_vm.masterDataPopup)?_c('vs-popup',{attrs:{"title":this.selectedApplicationData.person_name +
				' - ' +
				_vm.selectedApplicationData.course +
				' - ' +
				_vm.batchcode(_vm.selectedApplicationData),"active":_vm.masterDataPopup},on:{"update:active":function($event){_vm.masterDataPopup=$event}}},[_c('div',[_c('div',{},[_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" Person Name : "+_vm._s(this.selectedApplicationData.person_name)+" ")]),_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" Course : "+_vm._s(this.selectedApplicationData.course)+" ")])],1),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" Can-ID : "+_vm._s(this.selectedApplicationData.identity)+" ")]),_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" Batch : "+_vm._s(_vm.batchcode(this.selectedApplicationData))+" ")])],1),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" Spoc Name : "+_vm._s(_vm.getSpocName(this.selectedApplicationData.assigned_iiml_id))+" ")]),_c('vs-col',{attrs:{"vs-w":"6"}},[_vm._v(" City : "+_vm._s(this.selectedApplicationData.city)+" ")])],1),_c('vs-row',{staticClass:"gap"},[_c('vs-col',{attrs:{"vs-w":"8"}},[_vm._v(" Application created Date : "+_vm._s(this.createddate)+" ")])],1)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }